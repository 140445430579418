import { Practitioner } from "@cruncho/cruncho-shared-types";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "services/api";
import { AboutSection } from "./AboutSection";
import { CertificationsSection } from "./CertificationsSection/CertificationsSection";
import { ExperienceSection } from "./ExperienceSection/ExperienceSection";
import { FAQSection } from "./FAQSection/FAQSection";
import { InfoSection } from "./InfoSection";

export function PractitionerView() {
	const { practitionerId } = useParams<{ practitionerId: string }>();

	const [practitionerData, setPractitionerData] = useState<Practitioner>();
	useEffect(() => {
		if (practitionerId)
			api.practitioner
				.getPractitionerById(practitionerId)
				.then((data) => setPractitionerData(data));
	}, [practitionerId]);

	return (
		<Grid container spacing={2}>
			{practitionerData && practitionerId && (
				<>
					<Grid item xs={12}>
						<InfoSection
							practitionerData={practitionerData}
							practitionerId={practitionerId}
						/>
					</Grid>
					<Grid item xs={12}>
						<AboutSection practitionerData={practitionerData} />
					</Grid>
					<Grid item xs={6}>
						<ExperienceSection
							practitionerData={practitionerData}
							practitionerId={practitionerId}
						/>
					</Grid>
					<Grid item xs={6}>
						<CertificationsSection
							practitionerData={practitionerData}
							practitionerId={practitionerId}
						/>
					</Grid>
					<Grid item xs={12}>
						<FAQSection
							practitionerData={practitionerData}
							practitionerId={practitionerId}
						/>
					</Grid>
				</>
			)}
		</Grid>
	);
}
