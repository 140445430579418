import {
	BooleanWithDescriptionField,
	NumberWithDescriptionField,
	StringWithDescriptionField,
	WysiwygWithDescriptionField,
} from "@cruncho/components";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

/**
 * Section of fields related to eventmanager basic features and hooked to formik
 */
export function BasicFeaturesSection() {
	return (
		<div>
			<Typography variant="h6">Basic features</Typography>
			<Grid container spacing={2}>
				<Grid item xl={4} lg={6} xs={12}>
					<StringWithDescriptionField
						name="Consent Information text"
						description="The consent information displayed text "
						databaseName="features.eventManagerFeatures.consentInformation"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<StringWithDescriptionField
						name="Create Event Disclaimer"
						description="The disclaimer displayed for the create event section "
						databaseName="features.eventManagerFeatures.createEventDisclaimer"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<BooleanWithDescriptionField
						name="Notify New Event"
						description="If true, add a notification while creating a new event"
						databaseName="features.eventManagerFeatures.notifyNewEvent"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<StringWithDescriptionField
						name="Partner Approval"
						description=""
						databaseName="features.eventManagerFeatures.partnerApproval"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<BooleanWithDescriptionField
						name="Quiz Enabled"
						description="If true, enable the quiz"
						databaseName="features.eventManagerFeatures.quizEnabled"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<BooleanWithDescriptionField
						name="Target Group Field"
						description="If true, display the field Target Group"
						databaseName="features.eventManagerFeatures.targetGroupField"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<BooleanWithDescriptionField
						name='Hide "Publish your event" button'
						description='If true, removes the "Publish your event" button from the guides'
						databaseName="features.eventManagerFeatures.hidePublishYourEventButton"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<BooleanWithDescriptionField
						name="Print event list"
						description="If true, enable the print event list button"
						databaseName="features.eventManagerFeatures.printableEventList"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<BooleanWithDescriptionField
						name="Hide upcoming imported events"
						description="If true, hide the table for the upcoming imported events"
						databaseName="features.eventManagerFeatures.hideUpcomingImportedEvents"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<BooleanWithDescriptionField
						name="Hide default categories in the category field"
						description="If true, hide the default categories in the category field"
						databaseName="features.eventManagerFeatures.hideDefaultCategoriesInEM"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<NumberWithDescriptionField
						name="Maximum number of categories"
						databaseName="features.eventManagerFeatures.limitNumberCategory"
						description="The maximum categories that organizers can choose for their events."
						minValue={1}
						step={1}
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<NumberWithDescriptionField
						name="Maximum number of pictures"
						databaseName="features.eventManagerFeatures.limitNumberPicture"
						description="The maximum number of pictures that an event can uploaded."
						minValue={1}
						step={1}
					/>
				</Grid>
				<Grid item xl={8} lg={6} xs={12}>
					<WysiwygWithDescriptionField
						name="Create a description for the login page"
						description="The text displayed on the login page, gives the user more context when he or she logs in the eventmanager"
						databaseName="features.eventManagerFeatures.loginDescription"
					/>
				</Grid>
				<Grid item xl={8} lg={6} xs={12}>
					<WysiwygWithDescriptionField
						name="Create a description for picture uploading"
						description="The text displayed on the picture uploading area"
						databaseName="features.eventManagerFeatures.pictureDescription"
					/>
				</Grid>
			</Grid>
		</div>
	);
}
