import { TextField } from "@cruncho/components";
import { Practitioner } from "@cruncho/cruncho-shared-types";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import axios, { AxiosError } from "axios";
import { addMonths } from "date-fns";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { api } from "services/api";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

interface InfoSectionProps {
	practitionerData: Practitioner;
	practitionerId: string;
}

export function InfoSection({
	practitionerData,
	practitionerId,
}: InfoSectionProps) {
	const { enqueueSnackbar } = useSnackbar();

	const [noCompany, setNoCompany] = useState(
		practitionerData.noCompany ?? false,
	);

	const [isSponsor, setIsSponsor] = useState<boolean>(
		practitionerData.isSponsored ?? false,
	);

	const [isSponsorModified, setIsSponsorModified] = useState<boolean>(false);

	const handleisSponsor = () => {
		setIsSponsor(!isSponsor);
		setIsSponsorModified(!isSponsorModified);
	};

	const [endTrialDate, setEndTrialDate] = useState(new Date());

	// the number of months for the free trial period
	// 6 initially but the number can be changed
	const numberMonthsFreeTrial = 6;

	useEffect(() => {
		if (practitionerData.createdAccountAt) {
			const date = addMonths(
				new Date(practitionerData.createdAccountAt),
				numberMonthsFreeTrial,
			);
			setEndTrialDate(date);
		}
	}, [practitionerData.createdAccountAt, setEndTrialDate]);

	const introSchema = z.object({
		// Main information :
		firstname: z.string().min(1),
		lastname: z.string().min(1),
		uniqueURL: z.string().regex(/^[a-zA-Z0-9-_]+$/),
		type: z.string(),
		summary: z.string().optional(),
		videoFile: z
			.object({
				name: z.string().min(1),
				url: z.string().min(1),
				isUploadedFile: z.boolean(),
			})
			.optional(),
		companyName: z.string().optional(),
		companyNumber: z
			.string()
			.regex(/^\d{10}(\d{2})?$/)
			.nullable()
			.optional(),
		noCompany: z.boolean().optional(),
		phone: z.string().optional(),
		email: z.string().email().optional(),
		createdAccountAt: z
			.preprocess((arg) => {
				if (
					typeof arg === "number" ||
					typeof arg === "string" ||
					arg instanceof Date
				)
					return new Date(arg);
				return undefined;
			}, z.date())
			.or(z.string()),
		isSponsored: z.boolean().optional(),

		// Social links section :
		instagramLink: z.string().optional(),
		tiktokLink: z.string().optional(),
		linkedinLink: z.string().optional(),
		youtubeLink: z.string().optional(),
		xLink: z.string().optional(),
		facebookLink: z.string().optional(),
		websiteLink: z.string().optional(),
	});

	const initialValues = {
		// Info section :
		firstname: practitionerData.firstname ?? "",
		lastname: practitionerData.lastname ?? "",
		uniqueURL: practitionerData.uniqueURL ?? practitionerId,
		type: practitionerData.type ?? "",
		summary: practitionerData.summary ?? "",
		videoFile: practitionerData.videoFile ?? {
			name: "",
			url: "",
			isUploadedFile: false,
		},
		companyName: practitionerData.companyName ?? "",
		companyNumber: practitionerData.companyNumber ?? "",
		noCompany: practitionerData.noCompany,
		phone: practitionerData.phone ?? "",
		email: practitionerData.email ?? "",
		// This is a way to add the created date for those who has created their account before this commit
		createdAccountAt: practitionerData.createdAccountAt ?? new Date(),
		isSponsored: practitionerData.isSponsored ?? false,

		// Social links :
		instagramLink: practitionerData.instagramLink ?? "",
		tiktokLink: practitionerData.tiktokLink ?? "",
		linkedinLink: practitionerData.linkedinLink ?? "",
		youtubeLink: practitionerData.youtubeLink ?? "",
		xLink: practitionerData.xLink ?? "",
		facebookLink: practitionerData.facebookLink ?? "",
		websiteLink: practitionerData.websiteLink ?? "",
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={async (values, submitProps) => {
				try {
					setIsSponsorModified(false);
					values.isSponsored = isSponsor;
					values.noCompany = noCompany;
					if (values.videoFile.url !== practitionerData.videoFile?.url) {
						values.videoFile.name = values.videoFile.url;
						values.videoFile.isUploadedFile = false;
					}

					const toUpdateData = values;

					const res = await api.practitioner.updatePractitioner(
						practitionerId,
						toUpdateData,
					);

					if (res)
						enqueueSnackbar(`${res.firstname} updated!`, {
							variant: "success",
						});
					submitProps.resetForm({ values });
				} catch (error: any) {
					console.error(error);
					if (
						axios.isAxiosError(error) &&
						(error as AxiosError<{ message: string }>).response?.data?.message
					) {
						enqueueSnackbar(
							(error as AxiosError<{ message: string }>).response?.data?.message,
							{
								variant: "error",
								persist: true,
							},
						);
					} else {
						enqueueSnackbar(JSON.stringify(error, null, 2), {
							variant: "error",
							persist: true,
						});
					}
				}
			}}
			validationSchema={toFormikValidationSchema(introSchema)}
		>
			{({ dirty, isSubmitting, isValid }) => (
				<Form>
					<Card>
						<CardHeader title="Info section" />
						<CardContent>
							<Grid container xs={12}>
								<Grid item xs={2}>
									<span className="text-orange-500">
										Creation Date:{" "}
										{practitionerData.createdAccountAt
											? new Date(practitionerData.createdAccountAt).toDateString()
											: "No date"}
									</span>
								</Grid>
								<Grid item xs={3}>
									<span className="text-orange-500">
										End of trial Date:{" "}
										{practitionerData.createdAccountAt
											? endTrialDate.toDateString()
											: "No date"}
									</span>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								Sponsor this practitioner
								<Checkbox
									name="isSponsored"
									color="primary"
									checked={isSponsor}
									onClick={handleisSponsor}
								/>
							</Grid>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<TextField
										fullWidth
										name="firstname"
										label="First Name"
										variant="standard"
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										fullWidth
										name="uniqueURL"
										label="Unique URL"
										variant="standard"
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										fullWidth
										name="lastname"
										label="Last Name"
										variant="standard"
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										fullWidth
										name="type"
										label="Profession"
										variant="standard"
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										fullWidth
										name="summary"
										multiline
										label="Summary"
										variant="standard"
										InputProps={{ readOnly: true }}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										fullWidth
										name="videoFile.url"
										multiline
										label="URL of video"
										variant="standard"
									/>
								</Grid>
								<Grid item xs={3}>
									<TextField
										fullWidth
										name="instagramLink"
										multiline
										label="Instagram link"
										variant="standard"
									/>
								</Grid>
								<Grid item xs={3}>
									<TextField
										fullWidth
										name="tiktokLink"
										multiline
										label="Tiktok link"
										variant="standard"
									/>
								</Grid>
								<Grid item xs={3}>
									<TextField
										fullWidth
										name="facebookLink"
										multiline
										label="Facebook link"
										variant="standard"
									/>
								</Grid>
								<Grid item xs={2}>
									<TextField
										fullWidth
										name="youtubeLink"
										multiline
										label="Youtube link"
										variant="standard"
									/>
								</Grid>
								<Grid item xs={2}>
									<TextField
										fullWidth
										name="xLink"
										multiline
										label="X link"
										variant="standard"
									/>
								</Grid>
								<Grid item xs={2}>
									<TextField
										fullWidth
										name="linkedinLink"
										multiline
										label="Linkedin link"
										variant="standard"
									/>
								</Grid>
								<Grid item xs={2}>
									<TextField
										fullWidth
										name="websiteLink"
										multiline
										label="Personal website link"
										variant="standard"
									/>
								</Grid>
								<Grid item xs={2}>
									<Checkbox
										name="noComany"
										color="primary"
										checked={noCompany}
										onClick={() => setNoCompany(!noCompany)}
									/>
									Has no company
								</Grid>
								{!noCompany && (
									<>
										<Grid item xs={2}>
											<TextField
												fullWidth
												name="companyName"
												multiline
												label="Comapany name"
												variant="standard"
											/>
										</Grid>
										<Grid item xs={2}>
											<TextField
												fullWidth
												name="companyNumber"
												multiline
												label="Company number"
												variant="standard"
											/>
										</Grid>
									</>
								)}
								<Grid item xs={2}>
									<TextField fullWidth name="phone" label="Phone" variant="standard" />
								</Grid>
								<Grid item xs={2}>
									<TextField fullWidth name="email" label="Email" variant="standard" />
								</Grid>
							</Grid>
						</CardContent>
						<CardActions className="flex justify-end">
							<Button
								disabled={(!dirty && !isSponsorModified) || isSubmitting || !isValid}
								type="submit"
								variant="contained"
							>
								Save info
							</Button>
						</CardActions>
					</Card>
				</Form>
			)}
		</Formik>
	);
}
