import {
	BooleanWithDescriptionField,
	StringArrayWithDescriptionField,
	StringSelectWithDescriptionField,
	StringWithDescriptionField,
} from "@cruncho/components";
import { Destination } from "@cruncho/cruncho-shared-types";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Form, Formik, FormikErrors, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { api } from "services/api";

interface WidgetParameters {
	destination: string;
	domain: string;
	noGA: boolean;
	disableScrollBar: boolean;
	isFeaturedCarousel: boolean;
	/**
	 * Custom style for widget, give any class name that can let you customize the style, it is linked to customStyle, you will likely to use them together
	 */
	customClassName: string;
	/**
	 * Custom style for widget, please use regular css string
	 * This field is used to overwrite it, for instance: iframe-style.css set maxWidth as 1580px and the js script directly inject inline style <= This is frankly driving me crazy
	 */
	customStyle: string;
	options: {
		title: string;
		hideTitle: boolean;
		recosBaseUrl: string;
		redirectText: string;
		redirectLink: string;
		autoscroll: boolean;
		sortEvents: boolean;
		areaFilter: string;
		venue: string;
		l1: Array<string>;
		l2: Array<string>;
		l3: Array<string>;
		handpickedRecos: Array<string>;
		showOnlyHandpicked: boolean;
		disableFeaturedCarouselNavigation: boolean;
		/**
		 * This is for soulcircus widget, to display soulcircus events or practitioners
		 */
		widgetType: "event" | "practitioner" | string;
	};
}
interface ObserverProps {
	handleChange: (values: WidgetParameters) => void;
}
const Observer = ({ handleChange }: ObserverProps) => {
	const { values }: { values: WidgetParameters } = useFormikContext();

	useEffect(() => {
		handleChange(values);
	}, [values]);

	return null;
};
export const WidgetGeneratorView = () => {
	// Initial values so that Formik understands the types
	const initialValues: WidgetParameters = {
		destination: "",
		domain: "",
		noGA: false,
		disableScrollBar: false,
		isFeaturedCarousel: false,
		customClassName: "",
		customStyle: "",
		options: {
			title: "",
			hideTitle: false,
			recosBaseUrl: "",
			redirectText: "",
			redirectLink: "",
			autoscroll: true,
			sortEvents: false,
			areaFilter: "",
			venue: "",
			l1: [],
			l2: [],
			l3: [],
			handpickedRecos: [],
			showOnlyHandpicked: false,
			disableFeaturedCarouselNavigation: true,
			// soulcircus specific widget setting
			widgetType: "",
		},
	};

	// Destination options
	const [destinations, setDestinations] =
		useState<Array<Destination & { venues: string[] }>>();
	const [destinationOptions, setDestinationOptions] = useState<Array<string>>(
		[],
	);
	const [l1Options, setL1Options] = useState<Array<string>>([]);
	const [l2Options, setL2Options] = useState<Array<string>>([]);
	const [l3Options, setL3Options] = useState<Array<string>>([]);
	useEffect(() => {
		api.destination
			.getAllWithVenues()
			.then((data) => {
				data.sort((a, b) => (a._id < b._id ? -1 : 1));
				setDestinations(data);
				setDestinationOptions(data.map((destination) => destination._id));
			})
			.catch((error) => {
				console.error(error);
			});

		api.category.l1
			.getAll()
			.then((data) => {
				data.sort((a, b) => (a.slug < b.slug ? -1 : 1));
				setL1Options(data.map((l1) => l1.slug));
			})
			.catch((error) => {
				console.error(error);
			});

		api.category.l2
			.getAll()
			.then((data) => {
				data.sort((a, b) => (a.slug < b.slug ? -1 : 1));
				setL2Options(data.map((l2) => l2.slug));
			})
			.catch((error) => {
				console.error(error);
			});

		api.category.l3
			.getAll()
			.then((data) => {
				data.sort((a, b) => (a.slug < b.slug ? -1 : 1));
				setL3Options(data.map((l3) => l3.slug));
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	// Domain options
	const [domainOptions, setDomainOptions] = useState<Array<string>>([]);

	// Area options
	const [areaOptions, setAreaOptions] = useState<Array<string>>([]);

	// Venue options
	const [venueOptions, setVenueOptions] = useState<Array<string>>([]);

	// Selected destination
	const handleChange = (values: WidgetParameters) => {
		if (!destinations) return;

		const filteredDestinations = destinations.filter(
			(destination) => destination._id === values.destination,
		);
		if (!filteredDestinations.length) return;
		const selectedDestination = filteredDestinations[0];

		const domains = selectedDestination.subdomainMatch.split("|");
		if (!domains.includes(selectedDestination._id))
			domains.unshift(selectedDestination._id);
		setDomainOptions(domains);

		const areas = selectedDestination.areas
			.sort((a, b) => a.label.localeCompare(b.label))
			.map((area) => `${area.name};${area.label}`);
		setAreaOptions(areas);

		const venue = selectedDestination.venues.sort((a, b) => a.localeCompare(b));
		setVenueOptions(venue);
	};

	// Generated code
	const [code, setCode] = useState("");
	const [widgetUrl, setWidgetUrl] = useState("");

	// generate widget url for previewing
	useEffect(() => {
		const widgetUrlMatch = code.match(/CRUNCHO_WIDGET_URL\s*=\s*"([^"]+)"/);
		setWidgetUrl(widgetUrlMatch ? widgetUrlMatch[1] : "");
	}, [code]);

	const widgetStyleUrl =
		"https://s3.cruncho.co/cruncho-scripts/iframe-style.css";
	const featuredWidgetStyleUrl =
		"https://s3.cruncho.co/cruncho-scripts/featured-widget-style.css";

	const handleSubmit = (values: WidgetParameters) => {
		if (!destinations) return;

		if (!values.domain) return;

		const filteredDestinations = destinations.filter(
			(destination) => destination._id === values.destination,
		);
		if (!filteredDestinations) return;
		const selectedDestination = filteredDestinations[0];

		const filteredParameters = Object.entries(values.options).filter(
			([, value]) => typeof value === "boolean" || value.length,
		);
		const parameters = filteredParameters?.map(
			([key, value]) =>
				`${key}=${
					Array.isArray(value)
						? value.map((cat) => encodeURIComponent(cat))
						: encodeURIComponent(value)
				}`,
		);

		const customClassName = values.customClassName
			? `class="${values.customClassName}"`
			: "";
		const customStyle =
			customClassName && values.customStyle
				? `<style>.${values.customClassName}{${values.customStyle}}</style>`
				: "";
		const scrolling = `scrolling="${values.disableScrollBar ? "no" : "yes"}"`;

		const styleUrl = values.isFeaturedCarousel
			? featuredWidgetStyleUrl
			: widgetStyleUrl;

		const newCode = `<!-- Cruncho iframe sdk -->\n<script>\n\tvar CRUNCHO_WIDGET_URL = "https://${
			values.domain.slice(-1) === "." ? values.domain.slice(0, -1) : values.domain
		}.cruncho.co/${values.isFeaturedCarousel ? "featured-" : ""}widget${
			parameters ? `?${parameters.join("&")}` : ""
		}"\n</script>\n<script src="https://s3.cruncho.co/cruncho-scripts/sdk${
			values.noGA ? "-without-ga" : ""
		}.js"></script>\n<link rel="stylesheet" href=${styleUrl} />\n${customStyle}\n<!-- allowfullscreen is deprecated but is needed on old Firefox browsers -->\n<iframe\n\tid="cruncho_widget"\n\tsrc="javascript:void"\n\ttitle="Cruncho ${
			selectedDestination.name
		} guide"\n\tallow="geolocation; fullscreen"\n\tallowfullscreen\n\t${scrolling}\n\t${customClassName}\n>\n\tYour browser does not support the required features to show this guide\n</iframe>`;

		setCode(newCode);
	};

	// Validation
	const validate = (values: WidgetParameters) => {
		const errors: FormikErrors<WidgetParameters> = {};

		if (
			!destinationOptions.filter(
				(destination) => destination === values.destination,
			).length
		) {
			errors.destination = "Wrong destination";
		}

		if (
			!errors.destination &&
			values.domain !== values.destination &&
			!domainOptions.includes(values.domain)
		) {
			errors.domain = "Wrong domain";
		}

		if (
			!errors.destination &&
			errors.options &&
			values.options.areaFilter &&
			!areaOptions.filter((areaFilter) => areaFilter === values.options.areaFilter)
		) {
			errors.options.areaFilter = "Wrong area";
		}

		return errors;
	};

	return (
		<div className="w-[90%] m-auto">
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validate={validate}
			>
				{({ values }) => (
					<Form>
						<Observer handleChange={handleChange} />
						<Grid container style={{ flex: 1, flexDirection: "column" }} spacing={1}>
							<Grid item>
								<Stack spacing={1}>
									<Typography>General parameters</Typography>
									<BooleanWithDescriptionField
										name="Create featured carousel"
										databaseName="isFeaturedCarousel"
										description="Creating featured carousel"
									/>
									<StringSelectWithDescriptionField
										name="Destination"
										databaseName="destination"
										description="Select the destination for which to generate the widget code"
										arrayOptions={destinationOptions}
									/>
									<StringSelectWithDescriptionField
										name="Domain"
										databaseName="domain"
										description='Select the domain of the destination (the URL parameter that goes before ".cruncho.co") Be careful, only one of them may be correct. If you are not sure, ask a tech team member to check Cloudflare DNS config AND the wiki (there are known issues)'
										arrayOptions={domainOptions}
									/>
									{!values.isFeaturedCarousel && (
										<>
											<StringWithDescriptionField
												name="Title"
												databaseName="options.title"
												description="Set the title of the carousel"
											/>
											<BooleanWithDescriptionField
												name="Hide title"
												databaseName="options.hideTitle"
												description="Hide the title of the carousel"
											/>
											<StringWithDescriptionField
												name="Redirect text"
												databaseName="options.redirectText"
												description="Set the text of the redirect button"
											/>
											<StringWithDescriptionField
												name="Redirect link"
												databaseName="options.redirectLink"
												description="Link to redirect to when the redirect button is clicked"
											/>
											<StringWithDescriptionField
												name="Recommendations base link"
												databaseName="options.recosBaseLink"
												description="Base link to the recommendations, everything before the '/place' (ex: if recommendations are on 'https://example.com/guide#/place/...', put 'https://example.com/guide#'); defaults to the redirect link if not provided"
											/>
											<StringWithDescriptionField
												name="Soulcircus widget type"
												databaseName="options.widgetType"
												// expand the description if more types are available
												description="Define soulcircus widget type, for now, we have event and practitioner"
											/>
											<BooleanWithDescriptionField
												name="Autoscroll"
												databaseName="options.autoscroll"
												description="Make the carousel scroll one reco every few seconds"
											/>
											<BooleanWithDescriptionField
												name="Disable scrollbar"
												databaseName="disableScrollBar"
												description="Disable the vertical scrollbar"
											/>
											<BooleanWithDescriptionField
												name="Sort events"
												databaseName="options.sortEvents"
												description="Sort the events by date"
											/>
											<BooleanWithDescriptionField
												name="No Google Analytics"
												databaseName="noGA"
												description="Check this if you want to remove Google Analytics"
											/>
											<StringArrayWithDescriptionField
												name="Handpicked recos"
												databaseName="options.handpickedRecos"
												description="Select recommendations to show (please provide the hashed ids)(somedest.cruncho.co/place/{hashed-id})"
												freeSolo={true}
											/>
											<BooleanWithDescriptionField
												name="Show only handpicked recos"
												databaseName="options.onlyHandpickedRecos"
												description="Show only handpicked recos"
											/>
											<StringWithDescriptionField
												name="Custom class name"
												databaseName="customClassName"
												description="Give a custom class name to the iframe, you will likely to use it with custom widget style together"
											/>
											<StringWithDescriptionField
												name="Custom widget style"
												databaseName="customStyle"
												description="Give it a custom widget style based on the custom class name"
											/>
										</>
									)}
									{values.isFeaturedCarousel && (
										<>
											<BooleanWithDescriptionField
												name="Disable navigation"
												databaseName="options.disableFeaturedCarouselNavigation"
												description="Disable featured carousel card's navigation (Won't navigate to guide if click)"
											/>
											<BooleanWithDescriptionField
												name="Use latest events"
												databaseName="options.useLatestEvent"
												description="For featured carousel, use the latest recommendations instead of featured recommendations, Kungsbacka's special request"
											/>
										</>
									)}
								</Stack>
							</Grid>
							{!values.isFeaturedCarousel && (
								<Grid item>
									<Stack spacing={1}>
										<Typography>Filters</Typography>
										<StringSelectWithDescriptionField
											name="Area"
											databaseName="options.areaFilter"
											description="Select an area filter"
											arrayOptions={areaOptions}
										/>
										<StringSelectWithDescriptionField
											name="Venue"
											databaseName="options.venue"
											description="Select a venue filter"
											arrayOptions={venueOptions}
										/>
										<StringArrayWithDescriptionField
											name="L1"
											databaseName="options.l1"
											description="Select L1s to show (show all if empty)"
											arrayOptions={l1Options}
										/>
										<StringArrayWithDescriptionField
											name="L2"
											databaseName="options.l2"
											description="Select L2s to show (show all if empty)"
											arrayOptions={l2Options}
										/>
										<StringArrayWithDescriptionField
											name="L3"
											databaseName="options.l3"
											description="Select L3s to show (show all if empty)"
											arrayOptions={l3Options}
										/>
									</Stack>
								</Grid>
							)}
							<Grid item>
								<Button type="submit" aria-label="generate">
									Generate
								</Button>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
			<textarea
				className="w-full resize-none"
				value={code}
				readOnly
				style={{ height: "333px" }}
			/>
			{/* Render the code as HTML */}
			<link
				rel="stylesheet"
				href={
					widgetUrl.includes("featured") ? featuredWidgetStyleUrl : widgetStyleUrl
				}
			/>
			{widgetUrl && (
				<div className="w-full pb-3">
					<h3>Widget Preview:</h3>
					<iframe
						id="cruncho_widget"
						src={widgetUrl}
						title="Cruncho guide"
						allow="geolocation; fullscreen"
					>
						Your browser does not support the required features to show this guide
					</iframe>
				</div>
			)}
		</div>
	);
};
