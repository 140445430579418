import {
	BooleanWithDescriptionField,
	NumberWithDescriptionField,
} from "@cruncho/components";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export function EventCreationSection() {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h6">Event creation</Typography>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Show prefill with Facebook button"
					description="Show the prefill with Facebook button on the event creation form."
					databaseName="features.eventManagerFeatures.showFacebookPrefill"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Hide video field"
					description="Hide the video field from the event creation form."
					databaseName="features.eventManagerFeatures.hideVideoField"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Hide free tickets field"
					description="Hide the free tickets field from the event creation form."
					databaseName="features.eventManagerFeatures.hideFreeTicketsField"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Hide RSVP field"
					description="Hide the RSVP field from the event creation form."
					databaseName="features.eventManagerFeatures.hideRSVPField"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Show local field"
					description="Show the local event / not for tourists field on the event creation form."
					databaseName="features.eventManagerFeatures.showLocalField"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<NumberWithDescriptionField
					name="Preferred image width"
					description="Preferred width of the images to be uploaded(number only, unit is px, default is 1200px)"
					databaseName="features.eventManagerFeatures.preferredImage.width"
				/>
				<NumberWithDescriptionField
					name="Preferred image height"
					description="Preferred height of the images to be uploaded(number only, unit is px, default is 500px)"
					databaseName="features.eventManagerFeatures.preferredImage.height"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Activate tag system"
					description="Activate the tag system (kind of improved labels), with 3 levels of tag, visible by admin or not in the EM, and visible or not by users in the guide"
					databaseName="features.eventManagerFeatures.hasTagSystem"
				/>
			</Grid>
		</Grid>
	);
}
