import { Practitioner } from "@cruncho/cruncho-shared-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

interface AboutSectionProps {
	practitionerData: Practitioner;
}

export function AboutSection({ practitionerData }: AboutSectionProps) {
	return (
		<Card>
			<CardHeader title="About section" />
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="body1">
							{`${practitionerData.aboutSection?.description}`}
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}
