import { BugReport } from "@cruncho/cruncho-shared-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { useState } from "react";

import { currentEnvironment } from "../../services/api";

type BugReportsDetailsProps = {
	/**
	 * The list of bug reports to display
	 */
	bugReport: BugReport;
	/**
	 * Triggered when closing a bug report
	 */
	onBugReportClose: () => void;
	/**
	 * Triggered when rejecting a bug report
	 */
	onBugReportReject: () => void;
};

/**
 * The details of the selected bug report, giving the possibility to reject it or close it
 */
export function BugReportsDetails({
	bugReport,
	onBugReportClose,
	onBugReportReject,
}: BugReportsDetailsProps) {
	const [hoveredBugReportId, setHoveredBugReportId] = useState(false);
	const [startIdCopyAnimation, setStartIdCopyAnimation] = useState(false);
	const [hoveredBugReportEmail, setHoveredBugReportEmail] = useState(false);
	const [startEmailCopyAnimation, setStartEmailCopyAnimation] = useState(false);

	return (
		<Card>
			<CardHeader title="Details" />
			<CardContent>
				<List
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-start",
					}}
				>
					<ListItem sx={{ width: "auto" }}>
						<Chip label={bugReport.product} />
					</ListItem>
					<Divider orientation="vertical" flexItem variant="middle" />
					{bugReport.categories.map((category) => (
						<ListItem key={category} sx={{ width: "auto" }}>
							<Chip label={category} />
						</ListItem>
					))}
				</List>
			</CardContent>
			<Divider textAlign="left" variant="middle">
				<Typography color={"text.secondary"}>Mongo ID</Typography>
			</Divider>
			<CardContent>
				<Button // This button is almost identical as packages/components/src/ClipboardCopyButton.tsx but i needed to change the onClick function :'(
					size="medium"
					color="inherit"
					title="Copy"
					aria-label="Copy"
					onMouseEnter={() => setHoveredBugReportId(true)}
					onMouseLeave={() => setHoveredBugReportId(false)}
					onClick={async () => {
						setStartIdCopyAnimation(true);
						await navigator.clipboard.writeText(bugReport._id);
						setTimeout(() => setStartIdCopyAnimation(false), 1000);
					}}
				>
					<Typography textTransform={"none"}>{bugReport._id}</Typography>
					&nbsp;
					{(hoveredBugReportId || startIdCopyAnimation) && (
						<Typography textTransform={"none"} color={"primary"} fontSize={"0.8rem"}>
							{startIdCopyAnimation ? "Copied!" : "Copy"}
						</Typography>
					)}
				</Button>
			</CardContent>
			<Divider textAlign="left" variant="middle">
				<Typography color={"text.secondary"}>Report date</Typography>
			</Divider>
			<CardContent>
				<Typography>{bugReport.createdAt.toString()}</Typography>
			</CardContent>
			<Divider textAlign="left" variant="middle">
				<Typography color={"text.secondary"}>Reported on</Typography>
			</Divider>
			<CardContent>
				<Typography>{bugReport.reportPageUrl}</Typography>
			</CardContent>
			<Divider textAlign="left" variant="middle">
				<Typography color={"text.secondary"}>Description</Typography>
			</Divider>
			<CardContent>
				<Typography>{bugReport.description ?? "No description"}</Typography>
			</CardContent>
			<Divider textAlign="left" variant="middle">
				<Typography color={"text.secondary"}>Attachments</Typography>
			</Divider>
			<CardContent>
				{bugReport.attachments ? (
					<List>
						{bugReport.attachments.map((attachment, index) => {
							const imagePath = `https://bug-reports-${
								currentEnvironment === "production" ? "prod" : "staging"
							}.fra1.cdn.digitaloceanspaces.com/${bugReport._id}/${attachment}`;
							return (
								<ListItem
									key={attachment}
									divider={
										bugReport.attachments && index !== bugReport.attachments.length - 1
									}
									sx={{
										display: "flex",
										justifyContent: "center",
										width: "100%",
										height: "100%",
										objectFit: "contain",
									}}
								>
									<Box component={"img"} alt={imagePath} src={imagePath} />
								</ListItem>
							);
						})}
					</List>
				) : (
					"No attachments"
				)}
			</CardContent>
			{bugReport.navigator && (
				<>
					<Divider textAlign="left" variant="middle">
						<Typography color={"text.secondary"}>Browser details</Typography>
					</Divider>
					<CardContent>
						<Typography>
							<strong>Language</strong>: {bugReport.navigator.language}
						</Typography>
						<Typography>
							<strong>Browser</strong>: {bugReport.navigator.userAgent}
						</Typography>
					</CardContent>
				</>
			)}
			{bugReport.email && (
				<>
					<Divider textAlign="left" variant="middle">
						<Typography display={"inline"} color={"text.secondary"}>
							Email
						</Typography>
						&nbsp;-&nbsp;
						<Typography display={"inline"} color={"orange"}>
							This user {bugReport.acceptToBeContacted ? "accepted" : "did not want"}{" "}
							to be contacted
						</Typography>
					</Divider>
					<CardContent>
						<Button
							size="medium"
							color="inherit"
							title="Copy"
							aria-label="Copy"
							onMouseEnter={() => setHoveredBugReportEmail(true)}
							onMouseLeave={() => setHoveredBugReportEmail(false)}
							onClick={async () => {
								if (!bugReport.email) return;
								setStartEmailCopyAnimation(true);
								await navigator.clipboard.writeText(bugReport.email);
								setTimeout(() => setStartEmailCopyAnimation(false), 1000);
							}}
						>
							<Typography textTransform={"none"}>{bugReport.email}</Typography>
							&nbsp;
							{(hoveredBugReportEmail || startEmailCopyAnimation) && (
								<Typography
									textTransform={"none"}
									color={"primary"}
									fontSize={"0.8rem"}
								>
									{startEmailCopyAnimation ? "Copied!" : "Copy"}
								</Typography>
							)}
						</Button>
					</CardContent>
				</>
			)}
			<Divider />
			<CardActions sx={{ display: "flex", justifyContent: "space-evenly" }}>
				<Button
					onClick={onBugReportClose}
					color={"success"}
					variant={"outlined"}
					aria-label="close bug report"
				>
					Close
				</Button>
				<Button
					onClick={onBugReportReject}
					color={"error"}
					variant={"outlined"}
					aria-label="reject"
				>
					Reject
				</Button>
			</CardActions>
		</Card>
	);
}
